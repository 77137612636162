import { useLocation } from 'react-router-dom';

function useCanonicalUrl() {
  const location = useLocation();
  const baseUrl = process.env.PUBLIC_URL; // Replace with your base URL

  // Ensure the URL has a trailing slash if it doesn't already
  const pathWithTrailingSlash = location.pathname.endsWith('/')
    ? location.pathname
    : `${location.pathname}/`;

  const canonicalUrl = `${baseUrl}${pathWithTrailingSlash}`;

  return canonicalUrl;
}

export default useCanonicalUrl;