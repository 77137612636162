import React, {  lazy, Suspense, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import './index.css';
import GameOnQuotes from "../GameOnQuotes/Loadable";

const LeadFormModal = lazy(() => import('../../CommonComponents/LeadFormModal'));

const Banner = ({ gameOnQuotes }) => {
  const [modalState, setModalState] = useState({
    show: false,
    purpose: '',
    heading: ''
  });

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 991.9);

  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth > 991.9);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleModal = (purpose, heading) => {
    setModalState({
      show: true,
      purpose,
      heading
    });
  };

  const imgCDNURL1 = process.env.REACT_APP_CDN_LINK + "images/gameon-quotes/";

  return (
    <div className="bannerWrapper">
      {/* Background Video */}
      <div className="videoBackground">
        <video autoPlay muted loop playsInline className="backgroundVideo">
          <source src={isDesktop ? `${imgCDNURL1}angelai-commecial.mp4` : `${imgCDNURL1}angelai-commecial-mobile.mp4`} loading="eager" type="video/mp4" />
        </video>
      </div>

      <Container className="bannercontainer">
        <Row>
          <Col sm={12} lg={6}>
            <div className="truhomediv">
              <div className="heading">
                <h1 className="heading1">Your <span className="headingspan">True Home</span> <br />Is Always Within<sup style={{ fontSize: "40%", top: "-1.2em"}}>TM</sup></h1>
              </div>
              <div className="btndiv">
                <Button className="button blueButton" onClick={() => handleModal('PURCHASE', 'Apply For Purchase')}>
                  Purchase
                </Button>
                <Button className="button greenButton" onClick={() => handleModal('LIMITED CO', 'Apply For Refinance')}>
                  Refinance
                </Button>

                <Suspense fallback={null}>
                  {modalState.show && (
                    <div className="modalWrapper">
                      <LeadFormModal
                        loanOfficerId=""
                        branchCode="058"
                        leadSrc="NET001"
                        loanPurpKeyword={modalState.purpose}
                        formHeading={modalState.heading}
                        showLeadFormModal={modalState.show}
                        onHide={() => setModalState({ ...modalState, show: false })}
                      />
                    </div>
                  )}
                </Suspense>
              </div>
            </div>
          </Col>
          <Col sm={12} lg={6}>
            <div className="bannercarousel">
              <GameOnQuotes gameOnQuotes={gameOnQuotes} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Banner;
