import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Nav } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useLocation } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';
import './index.css';
import Container from "react-bootstrap/Container";
import UserWayAccessibilityMenu from "../UserWayAccessibilityMenu"

const LeadFormModal = lazy(() => import("../LeadFormModal"));
const ConsentModal = lazy(() => import("../../HomepageComponent/ConsentModal"));

const CustomNavbar = () => {
  const location = useLocation();

  useEffect(() => {
    const queryData = new URLSearchParams(location.search);
    const rlo = queryData.get('rlo') || '';
    const blof = queryData.get('blof') || '';
    if (rlo) {
      sessionStorage.setItem('rlo', rlo);
    }
    if (blof) {
      sessionStorage.setItem('blof', blof);
    }
  }, []);

  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const handleShowWelcomeModal = () => setShowWelcomeModal(!showWelcomeModal);
  const handleCloseWelcomeModal = () => setShowWelcomeModal(false);

  const [logoImage, setLogoImage] = useState('');
  const imgCDNURL = process.env.REACT_APP_HW_CDN;
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [showLeadForm, setShowLeadForm] = useState(false);
  const [showConsent, setShowConsent] = useState(false);
  const [navbarExpanded, setNavbarExpanded] = useState(false);
  const handleShowLeadFormModal = () => setShowLeadForm(true);
  const handleCloseLeadFormModal = () => setShowLeadForm(false);
  const [navbar, setNavbar] = useState(false);
  const handleShowConsentModal = () => setShowConsent(true);
  const handleCloseConsentModal = () => setShowConsent(false);

  // Modified dropdown handlers to close other dropdowns when one is opened
  const toggleDropdown = (setter, currentDropdown) => {
    return (e) => {
      // Prevent event from bubbling to parent elements
      e.stopPropagation();
      
      // Close all other dropdowns first
      if (currentDropdown === 'about') {
        setShow1(false);
        setShow2(false);
      } else if (currentDropdown === 'loanTools') {
        setShow(false);
        setShow2(false);
      } else if (currentDropdown === 'clientLogin') {
        setShow(false);
        setShow1(false);
      }
      
      // Toggle the current dropdown
      setter(prev => !prev);
    };
  };

  const showDropdown = toggleDropdown(setShow, 'about');
  const showDropdown1 = toggleDropdown(setShow1, 'loanTools');
  const showDropdown2 = toggleDropdown(setShow2, 'clientLogin');

  // Close navbar when any navigation link is clicked - no parameters needed
  const handleNavLinkClick = () => {
    // Close the navbar and dropdowns
    setNavbarExpanded(false);
    setShow(false);
    setShow1(false);
    setShow2(false);
  };

  // New function specifically for external links
  const handleExternalLinkClick = (url) => (e) => {
    e.preventDefault();
    // Close the navbar and dropdowns
    setNavbarExpanded(false);
    setShow(false);
    setShow1(false);
    setShow2(false);
    // Open in new tab
    window.open(url, '_self', 'noopener,noreferrer');
  };

  // Apply Now button handler - separate from regular nav links
  const handleApplyNowClick = () => {
    handleShowLeadFormModal();
    handleNavLinkClick();
  };

  const isMobileOrTablet = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const isMobile = width <= 768 && height <= 1024;
    const isTablet = width > 768 && width <= 991;

    return isMobile || isTablet;
  }

  const handleToggle = () => {
    // Toggle the expanded state
    setNavbarExpanded(!navbarExpanded);

    // Close all dropdowns when toggling the navbar
    setShow(false);
    setShow1(false);
    setShow2(false);
  };

  useEffect(() => {
    let prevScrollPos = window.scrollY;

    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const Navbar = document.querySelector('.homepageNav');

      const isDesktop = !isMobileOrTablet();

      if (currentScrollPos === 0) {
        Navbar.classList.remove("navbarTransition");
        setNavbar(false);
      } else {
        if (prevScrollPos > currentScrollPos) {
          // Scrolling up - make navbar visible for both desktop and mobile
          setNavbar(true);
          Navbar.classList.remove("navbarTransition");
        } else {
          // Scrolling down
          if (isDesktop) {
            setNavbar(true);
          } else {
            // Only hide navbar when scrolling down on mobile/tablet
            setNavbar(false);
            Navbar.classList.add("navbarTransition");
            // Close the navbar when scrolling down on mobile/tablet
            setNavbarExpanded(false);
          }
        }
      }

      // Close any open dropdown menus
      setShow(false);
      setShow1(false);
      setShow2(false);

      prevScrollPos = currentScrollPos;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMobileOrTablet]);

  useEffect(() => {
    if (window.innerWidth >= 767) {
      setLogoImage(
        'https://resources.swmc.com/swmc-images/Homepage/New/SunWest41YearsofTrust-Blue.webp',
      );
    } else {
      setLogoImage(
        'https://resources.swmc.com/swmc-images/Homepage/New/SunWest41YearsofTrust-White.webp',
      );
    }
  }, []);

  const currentPath = location.pathname;
  const getAccessibilityMenuColor = () => {
    // [Previous getAccessibilityMenuColor implementation remains the same]
    if (
      currentPath === '/careers' ||
      currentPath.includes('/careers/') ||
      currentPath === '/loss-mitigation' ||
      currentPath.includes('/loss-mitigation') ||
      currentPath === '/faq' ||
      currentPath.includes('/faq') ||
      currentPath === '/turn-time-commitment' ||
      currentPath.includes('/turn-time-commitment') ||
      currentPath === '/privacy-policy' ||
      currentPath.includes('/privacy-policy') ||
      currentPath === '/financial-related-links' ||
      currentPath.includes('/financial-related-links') ||
      currentPath === '/reverse-mortgage-wave' ||
      currentPath.includes('/reverse-mortgage-wave/') ||
      currentPath === '/swmc-lender-alerts' ||
      currentPath.includes('/swmc-lender-alerts/') ||
      currentPath === '/approved-title-underwriters' ||
      currentPath.includes('/approved-title-underwriters/') ||
      currentPath === '/partner-resources' ||
      currentPath.includes('/partner-resources/') ||
      currentPath === '/disaster-recovery' ||
      currentPath.includes('/disaster-recovery/') ||
      currentPath === '/hud-handbook-203k' ||
      currentPath.includes('/hud-handbook-203k/') ||
      currentPath === '/feedback' ||
      currentPath.includes('/feedback/') ||
      currentPath === '/disclaimer' ||
      currentPath.includes('/disclaimer/') ||
      currentPath === '/avoid-affinity-fraud' ||
      currentPath.includes('/avoid-affinity-fraud/') ||
      currentPath === '/covid19-relief' ||
      currentPath.includes('/covid19-relief/') ||
      currentPath === '/markets' ||
      currentPath.includes('/markets/') ||
      currentPath === '/hud-handbook-faq' ||
      currentPath.includes('/hud-handbook-faq/') ||
      currentPath === '/training-videos' ||
      currentPath.includes('/training-videos/') ||
      currentPath === '/loan-programs' ||
      currentPath.includes('/loan-programs/') ||
      currentPath === '/property-insurance' ||
      currentPath.includes('/property-insurance/') ||
      currentPath === '/cutoff-dates' ||
      currentPath.includes('/cutoff-dates/') ||
      currentPath === '/biweekly-vs-additional-payment' ||
      currentPath.includes('/biweekly-vs-additional-payment/') ||
      currentPath === '/mortgage-related-training-guides' ||
      currentPath.includes('/mortgage-related-training-guides/') ||
      currentPath === '/myloaninfo/login' ||
      currentPath.includes('/myloaninfo/login/') ||
      currentPath === '/swmc-market-rate-trends' ||
      currentPath.includes('/swmc-market-rate-trends/') ||
      currentPath === '/loan-program-faq' ||
      currentPath.includes('/loan-program-faq/') ||
      currentPath === '/*' ||
      currentPath.includes('/*/') ||
      currentPath === '/borrower-concerns-complaints' ||
      currentPath.includes('/borrower-concerns-complaints/') ||
      currentPath === '/comparison' ||
      currentPath.includes('/comparison/') ||
      currentPath === '/swmc-site-map' ||
      currentPath.includes('/swmc-site-map/') ||
      currentPath === '/txdis' ||
      currentPath.includes('/txdis/') ||
      currentPath === '/loan-calculators-mortgage-calculators/appraisal-calculators' ||
      currentPath.includes('/loan-calculators-mortgage-calculators/appraisal-calculators/') ||
      currentPath === '/sunsoft-login' ||
      currentPath.includes('/sunsoft-login/') ||
      currentPath === '/login' ||
      currentPath.includes('/login/') ||
      currentPath === '/swmc-press-releases' ||
      currentPath.includes('/swmc-press-releases/') ||
      currentPath === '/underwriting-scenario' ||
      currentPath.includes('/underwriting-scenario/') ||
      currentPath === '/hpml-vs-rebuttable-presumption' ||
      currentPath.includes('/hpml-vs-rebuttable-presumption/') ||
      currentPath === '/our-history' ||
      currentPath.includes('/our-history/')
    ) {
      return 'black';
    }
    return 'white';
  };

  // Modified handlers for mouse enter/leave to ensure only one dropdown is open at a time
  const handleMouseEnter = (setter, currentDropdown) => {
    return () => {
      if (!isMobileOrTablet()) {
        // Close other dropdowns first
        if (currentDropdown === 'about') {
          setShow1(false);
          setShow2(false);
        } else if (currentDropdown === 'loanTools') {
          setShow(false);
          setShow2(false);
        } else if (currentDropdown === 'clientLogin') {
          setShow(false);
          setShow1(false);
        }
        
        // Open current dropdown
        setter(true);
      }
    };
  };

  const handleMouseLeave = (setter) => {
    return () => {
      if (!isMobileOrTablet()) {
        setter(false);
      }
    };
  };

  // Modified dropdown click handler for mobile to ensure only one dropdown is open
  const handleDropdownClick = (setter, currentDropdown) => {
    return (e) => {
      if (isMobileOrTablet()) {
        e.preventDefault();
        e.stopPropagation();
        
        // Close other dropdowns first
        if (currentDropdown === 'about') {
          setShow1(false);
          setShow2(false);
        } else if (currentDropdown === 'loanTools') {
          setShow(false);
          setShow2(false);
        } else if (currentDropdown === 'clientLogin') {
          setShow(false);
          setShow1(false);
        }
        
        // Toggle current dropdown
        setter(prev => !prev);
      }
    };
  };

  return (
    <div>
      <Navbar
        collapseOnSelect
        expand="lg"
        fixed="top"
        variant="dark"
        className="homepageNav"
        expanded={navbarExpanded}
      >
        <Navbar.Brand>
          <NavLink to={{ pathname: '/', params: '' }} onClick={handleNavLinkClick}>
            <img
              src={logoImage}
              className="main_logo"
              alt="swmc-logo"
              loading="lazy"
              width="220"
              height="55"
            />
          </NavLink>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={handleToggle}>
          {navbarExpanded ? (
            <span className="close-icon">x</span>
          ) : (
            <span className="burger-icon">☰</span>
          )}
        </Navbar.Toggle>

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto" style={{ margin: 'auto' }} />
          <Nav className="nav_temp">
            <NavDropdown
              title="About"
              id="collasible-nav-dropdown"
              className="dropdown"
              show={show}
              onClick={handleDropdownClick(setShow, 'about')}
              onMouseEnter={handleMouseEnter(setShow, 'about')}
              onMouseLeave={handleMouseLeave(setShow)}
            >
              <NavDropdown.Item
                href
                as={NavLink}
                to="/our-history"
                onClick={handleNavLinkClick}
              >
                Our History
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://www.swmc.com/leadership/search"
                onClick={handleExternalLinkClick("https://www.swmc.com/leadership/search")}
              >
               Our Leadership
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://www.swmc.com/why-sunwest/"
                onClick={handleExternalLinkClick("https://www.swmc.com/why-sunwest/")}
              >
                Success Stories
              </NavDropdown.Item>
              <NavDropdown.Item
                href
                as={NavLink}
                to="/careers"
                onClick={handleNavLinkClick}
              >
                Careers
              </NavDropdown.Item>
              <NavDropdown.Item
                href
                as={NavLink}
                to="/contact-us"
                onClick={handleNavLinkClick}
              >
                Contact Us
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              title="Loan Tools"
              id="collasible-nav-dropdown"
              show={show1}
              onClick={handleDropdownClick(setShow1, 'loanTools')}
              onMouseEnter={handleMouseEnter(setShow1, 'loanTools')}
              onMouseLeave={handleMouseLeave(setShow1)}
              className="dropdown"
            >
              <NavDropdown.Item
                href="https://www.swmc.com/angelai/calculators"
                onClick={handleExternalLinkClick("https://www.swmc.com/angelai/calculators")}
              >
                Calculators
              </NavDropdown.Item>
              <NavDropdown.Item
                href
                as={NavLink}
                to="/loan-programs"
                onClick={handleNavLinkClick}
              >
                Loan Products
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://www.swmc.com/blog/"
                onClick={handleExternalLinkClick("https://www.swmc.com/blog/")}
              >
                Blog
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://sunwestmortgage.myloaninfo.com/loanmanager/glossary/"
               
                target='_blank'
              >
                Glossary
              </NavDropdown.Item>
              <NavDropdown.Item
                href
                as={NavLink}
                to="/faq"
                onClick={handleNavLinkClick}
              >
                FAQ
              </NavDropdown.Item>

              <NavDropdown.Item
                href="https://www.swmc.com/theprocessor"
                onClick={handleExternalLinkClick("https://www.swmc.com/theprocessor")}
              >
                The Processor
              </NavDropdown.Item>

              <NavDropdown.Item
                href
                to="/turn-time-commitment"
                as={NavLink}
                onClick={handleNavLinkClick}
              >
                Turn Time Commitment
              </NavDropdown.Item>

              <NavDropdown.Item
                href="https://www.swmc.com/angelai/"
                onClick={handleExternalLinkClick("https://www.swmc.com/angelai/")}
              >
                AskAngel.Ai
              </NavDropdown.Item>

              <NavDropdown.Item
                href="https://www.swmc.com/priceengine/"
                onClick={handleExternalLinkClick("https://www.swmc.com/priceengine/")}
              >
                Pricing Engine
              </NavDropdown.Item>

              <NavDropdown.Item
                href="https://www.swmc.com/down-payment-assistance/search"
                onClick={handleExternalLinkClick("https://www.swmc.com/down-payment-assistance/search")}
              >
                Down Payment Assistance
              </NavDropdown.Item>
              
            </NavDropdown>

            {location.pathname !== '/tpo' && location.pathname !== '/tpo/' && location.pathname !== '/tpo/guidelines' && location.pathname !== '/tpo/guidelines/' && location.pathname !== '/tpo/guidelines#wholesale' && location.pathname !== '/tpo/guidelines#wholesale/' && (
              <Nav.Link
                as="a"
                href="https://sunwestpartners.com/"
                target='_blank'
              >
                Retail
              </Nav.Link>
            )}
            
            <Nav.Link
              href
              as={NavLink}
              to="/tpo"
              rel="noopener noreferrer"
              onClick={handleNavLinkClick}
            >
              Wholesale
            </Nav.Link>
            
            <Nav.Link
              href
              as={NavLink}
              to="/correspondent-lending"
              rel="noopener noreferrer"
              onClick={handleNavLinkClick}
            >
              Correspondent
            </Nav.Link>

            <NavDropdown
              title="Client Login"
              id="collasible-nav-dropdown"
              show={show2}
              onClick={handleDropdownClick(setShow2, 'clientLogin')}
              onMouseEnter={handleMouseEnter(setShow2, 'clientLogin')}
              onMouseLeave={handleMouseLeave(setShow2)}
              className="dropdown"
            >
              <NavDropdown.Item
                href="https://seemyloanstatus.com/ReverseMortgage/jsp/extSeeMyLoanStatusLogin.jsf?loginByUsingView=CMOL"
                target='_blank'
              >
                Check My Loan Progress
              </NavDropdown.Item>
              <NavDropdown.Item
                href
                as={NavLink}
                to="/myloaninfo/login"
                onClick={handleNavLinkClick}
              >
                My Loan Info / Make a Payment
              </NavDropdown.Item>

              <NavDropdown.Item
                href
                as={NavLink}
                to="/loss-mitigation"
                onClick={handleNavLinkClick}
              >
                Servicing Portal
              </NavDropdown.Item>
             
              <NavDropdown.Item
                href
                as={NavLink}
                to="/property-insurance"
                onClick={handleNavLinkClick}
              >
                Property Insurance
              </NavDropdown.Item>

              <NavDropdown.Item
                href
                as={NavLink}
                to="/sunsoft-login"
                onClick={handleNavLinkClick}
              >
                ReverseSoft Login
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link
              href
              as={NavLink}
              to="/login"
              rel="noopener noreferrer"
              onClick={handleNavLinkClick}
            >
              Partner Login
            </Nav.Link>

            {location.pathname === '/loan-officer-search' || location.pathname === '/loan-officer-search/' || location.pathname === '/swmc-branch/' ||
              location.pathname === '/swmc-branch' ? (
              <Button
                className="homepageButtonApply"
                disabled
                variant="primary"
                size="md"
                onClick={handleApplyNowClick}
              >
                Apply Now
              </Button>
            ) : (
              <Button
                className="homepageButtonApply"
                variant="primary"
                size="md"
                onClick={handleApplyNowClick}
                type="submit"
              >
                Apply Now
              </Button>
            )}

            <div>
              {modalData && modalData.showModal === 0 && (
                <div className="navbarmodalbtn">
                  <div
                    className="navbarmodalbtnDesktop"
                    onClick={handleShowWelcomeModal}
                  >
                    <img className="navbarmodalbtn" src={`${imgCDNURL}button2000_desk.webp`} alt="Modal button" />
                  </div>
                </div>
              )}
            </div>
          </Nav>
        </Navbar.Collapse>
        <div className="desktopaccesibility">
          <UserWayAccessibilityMenu color="black" width={40} height={40} />
        </div>
      </Navbar>
      <div className='accessibilityDiv'>
        <div className="mobileaccesibilty">
          <Container>
            <UserWayAccessibilityMenu color={getAccessibilityMenuColor()} width={40} height={40} />
          </Container>
        </div>
      </div>
      <Suspense fallback={<div></div>}>
        <LeadFormModal
          loanOfficerId=""
          branchCode="058"
          leadSrc="NET001"
          loanPurpKeyword=""
          formHeading="Apply Now"
          showLeadFormModal={showLeadForm}
          onHide={handleCloseLeadFormModal}
        />
        <ConsentModal
          showConsent={showConsent}
          onHide={handleCloseConsentModal}
        />
      </Suspense>
    </div>
  );
};

export default CustomNavbar;