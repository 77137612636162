import React, { useEffect, Suspense, lazy, useState, useRef } from 'react';
import CacheBuster from "react-cache-buster";
import appversion from "../package.json";
import Navbar from "./components/CommonComponents/Navbar";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Homepage from "./components/HomepageComponent";
import { CookieSetting } from "cookiesetting-component";
import useCanonicalUrl from './utils/UseCanonicalUrl/useCanonicalUrl';
import { Helmet } from "react-helmet";
const TermsAndConditionPage = lazy(() => import('./components/SubPages/TermsAndConditionPage/Terms'));
const TexasDisclosure = lazy(() => import('./components/SubPages/TexasDisclosure'));
const FaqPage = lazy(() => import('./components/SubPages/FaqPage'));
const FaqTpoPage = lazy(() => import('./components/SubPages/FaqTpoPage'));
const PropertyInsurance = lazy(() => import('./components/SubPages/PropertyInsurance'));
const Disclaimer = lazy(() => import('./components/SubPages/Disclaimer'));
const TrueTerms = lazy(() => import('./components/SubPages/TruTerms/index'));
const PrivacyPolicy = lazy(() => import('./components/SubPages/PrivacyPolicy'));
const CommonFooter = lazy(() => import('./components/CommonComponents/Footer/Footer'));
const SiteMap = lazy(() => import('./components/SubPages/SiteMap'));
const OurHistory = lazy(() => import('./components/SubPages/OurHistory'));
const LoanPrograms = lazy(() => import('./components/SubPages/LoanPrograms'));
const TurnTimeCommitment = lazy(() => import('./components/SubPages/TurnTimeCommitment'));
const PartnerLogin = lazy(() => import('./components/SubPages/Login/index'));
const SunsoftLogin = lazy(() => import('./components/SubPages/SunsoftLogin/index'));
const CareersPage = lazy(() => import('./components/SubPages/Careers/index'));
const ContactUs = lazy(() => import('./components/SubPages/ContactUs/index'));
const ContactLoanServicing = lazy(() => import('./components/SubPages/ContactLoanServicing/index'));
const CutOffDates = lazy(() => import('./components/SubPages/CutoffDates/index'));
const BorrowerConcernsComplaints = lazy(() => import('./components/SubPages/BorrowerConcernsComplaints/index'));
const ServicingPortal = lazy(() => import('./components/SubPages/ServicingPortal/index'));
const SubmitResume = lazy(() => import('./components/SubPages/SubmitResume/index'));
const SocialMediaDisclaimer = lazy(() => import('./components/SubPages/SocialMediaDisclaimers/index'));
const MyLoanInfoLogin = lazy(() => import('./components/SubPages/MyLoanInfoLogin/index'));
const NationWideLending = lazy(() => import('./components/SubPages/NationwideLending/index'));
const MarketCommentary = lazy(() => import('./components/SubPages/MarketCommentary/index'));
const FinancialLinks = lazy(() => import('./components/SubPages/FinancialLinks/index'));
const PartnerResources = lazy(() => import('./components/SubPages/PartnerResources/PartnerResources'));
const WholesaleAndCorrespondentProductGuideLines = lazy(() => import('./components/SubPages/WholesaleAndCorrespondentGuidelines/'));
const SpecificBranchPage = lazy(() => import('./components/SubPages/SpecificBranchPage/index'));
const HPMLPage = lazy(() => import('./components/SubPages/HPMLPage/index'));
const LoanProgramFaq = lazy(() => import('./components/SubPages/LoanProgramFaq/index'));
const WholesaleLendingForms = lazy(() => import('./components/SubPages/WholesaleLendingForms/index'));
const MortgageRelatedTrainingGuides = lazy(() => import('./components/SubPages/MortgageRelatedTrainingGuides/index'));
const LenderAlerts = lazy(() => import('./components/SubPages/LenderAlerts/index'));
const ApprovedUnderwriterPage = lazy(() => import('./components/SubPages/ApprovedUnderwriters/index'));
const WholesaleLending = lazy(() => import('./components/SubPages/WholesaleLending/index'));
const CorrespondentLending = lazy(() => import('./components/SubPages/CorrespondentLending/index'));
const TrainingVideos = lazy(() => import('./components/SubPages/TrainingVideos/index'));
const BiweeklyvsAdditionalCalculator = lazy(() => import('./components/SubPages/BiweeklyvsAdditionalCalculator/index'));
const DisasterRecovery = lazy(() => import('./components/SubPages/DisasterRecovery/index'));
const IncomeDocumentation = lazy(() => import('./components/SubPages/AcceptableIncomeDocumentation/index'));
const ComparisonPage = lazy(() => import('./components/SubPages/ComparisonPage/index'));
const HudHandbookFaq = lazy(() => import('./components/SubPages/HudHandbookFaq/index'));
const FeedbackPage = lazy(() => import('./components/SubPages/Feedback/index'));
const HUDSummaryPage = lazy(() => import('./components/SubPages/HUDHandBookSummary/index'));
const HUD203kPage = lazy(() => import('./components/SubPages/HUDHandBookFHA203k/index'));
const MarketRateTrends = lazy(() => import('./components/SubPages/MarketRateTrend/index'));
const Covid19Relief = lazy(() => import('./components/SubPages/Covid19Relief/index'));
const LoanOfficerSearch = lazy(() => import('./components/SubPages/LoanOfficerSearch/index'));
const SwmcPressRelease = lazy(() => import('./components/SubPages/SwmcPressReleases/index'));
const AffinityFraud = lazy(() => import('./components/SubPages/AffinityFraud'));
const ErrorFallback = lazy(() => import('./components/SubPages/ErrorFallback'));
const SwmcOffers = lazy(() => import('./components/SubPages/SwmcOffers/index'));
const UnderwritingScenario = lazy(() => import('./components/SubPages/UnderwritingScenario/UnderwritingScenario'));
const Markets = lazy(() => import('./components/SubPages/Markets/index'));
const AppraisalCalculator = lazy(() => import('./components/SubPages/AppraisalCalculator/index'));
const ScrollToTop = lazy(() => import('./utils/ScrollToTop'));
const AngelAiCalculators = lazy(() => import('./components/SubPages/AngelAiCalculators/index'));

const Layout = ({ children }) => {
  const location = useLocation();
  const canonicalUrl = useCanonicalUrl();
  const [showFooter, setShowFooter] = useState(false);
  const footerObserverRef = useRef(null);
  
  // List of paths that should always show the footer immediately
  const alwaysShowFooterPaths = [
    "/login", 
    "/sunsoft-login", 
    "/myloaninfo/login", 
    "/wholesale-lending-forms", 
    "/loan-officer-search", 
    "/mortgage-related-training-guides", 
    "/feedback", 
    "/covid19-relief",
    "/loan-calculators-mortgage-calculators/appraisal-calculators",
    "/swmc-offer",
    "/contact-loan-servicing"
  ];
  
  // Check if current path is in the list
  const isInAlwaysShowFooterList = alwaysShowFooterPaths.some(
    path => location.pathname === path || location.pathname === `${path}/`
  );

  useEffect(() => {
    // Create Intersection Observer to detect when footer area becomes visible
    const options = {
      root: null, // Use viewport as root
      rootMargin: '400px 0px', // Start loading 400px before it enters viewport
      threshold: 0 // Trigger as soon as any part becomes visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setShowFooter(true);
          // Once footer is shown, disconnect observer
          observer.disconnect();
        }
      });
    }, options);

    // If we're on a path that always needs the footer, show it immediately
    if (isInAlwaysShowFooterList) {
      setShowFooter(true);
    } else if (footerObserverRef.current) {
      // Otherwise, observe the footer placeholder
      observer.observe(footerObserverRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [location.pathname, isInAlwaysShowFooterList]);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Navbar />
      
      {children}
      
      {/* Footer placeholder for Intersection Observer */}
      <div 
        ref={footerObserverRef} 
        style={{ height: '10px', width: '100%', marginTop: '-10px' }} 
        aria-hidden="true"
      />
      
      {/* Only render the footer when showFooter is true */}
      {showFooter && (
        <Suspense fallback={<div></div>}>
          <CommonFooter />
        </Suspense>
      )}
    </>
  );
};

const App = () => {
  const appVersion = appversion.version;
  const isProduction = process.env.NODE_ENV === "production";

  return (
    <BrowserRouter future={{ v7_relativeSplatPath: true, v7_startTransition: true }}>

      <>
          <Layout>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route
                path="/*"
                element={
                  <Suspense fallback={<div className="text-center"></div>}>
                    <Routes>
                      <Route path="/borrower-concerns-complaints/" element={<BorrowerConcernsComplaints />} />
                      <Route path="/disclaimer/" element={<Disclaimer />} />
                      <Route path="/txdis/" element={<TexasDisclosure />} />
                      <Route path="/terms-and-conditions/" element={<TermsAndConditionPage />} />
                      <Route path="/faq/" element={<FaqPage />} />
                      <Route path="/faq-clients/" element={<FaqTpoPage />} />
                      <Route path="/nationwide-lending/" element={<NationWideLending />} />
                      <Route path="/property-insurance/" element={<PropertyInsurance />} />
                      <Route path="/privacy-policy/" element={<PrivacyPolicy />} />
                      <Route path="/swmc-site-map/" element={<SiteMap />} />
                      <Route path="/our-history/" element={<OurHistory />} />
                      <Route path="/loan-programs/" element={<LoanPrograms />} />
                      <Route path="/turn-time-commitment/" element={<TurnTimeCommitment />} />
                      <Route path="/login/" element={<PartnerLogin />} />
                      <Route path="/sunsoft-login/" element={<SunsoftLogin />} />
                      <Route path="/careers/" element={<CareersPage />} />
                      <Route path="/borrower-concerns-complaints/" element={<BorrowerConcernsComplaints />} />
                      <Route path="/contact-us/" element={<ContactUs />} />
                      <Route path="/contact-loan-servicing/" element={<ContactLoanServicing />} />
                      <Route path="/cutoff-dates/" element={<CutOffDates />} />
                      <Route path="/submit-resume/" element={<SubmitResume />} />
                      <Route path="/swmc-market-commentary/" element={<MarketCommentary />} />
                      <Route path="/myloaninfo/login/" element={<MyLoanInfoLogin />} />
                      {/* <Route path="/reverse-mortgage-wave/" element={<MortgageWavePage />} /> */}
                      <Route path="/financial-related-links/" element={<FinancialLinks />} />
                      <Route path="/partner-resources/" element={<PartnerResources />} />
                      <Route path="/correspondent-lending/guidelines/" element={<WholesaleAndCorrespondentProductGuideLines />} />
                      <Route path="/tpo/guidelines/" element={<WholesaleAndCorrespondentProductGuideLines />} />
                      <Route path="/tru-terms/" element={<TrueTerms />} />
                      <Route path="/swmc-branch/" element={<SpecificBranchPage />} />
                      <Route path="/smdisclaimer/" element={<SocialMediaDisclaimer />} />
                      <Route path="/hpml-vs-rebuttable-presumption/" element={<HPMLPage />} />
                      <Route path="/loan-program-faq/" element={<LoanProgramFaq />} />
                      <Route path="/wholesale-lending-forms/" element={<WholesaleLendingForms />} />
                      <Route path="/mortgage-related-training-guides/" element={<MortgageRelatedTrainingGuides />} />
                      <Route path="/swmc-lender-alerts/" element={<LenderAlerts />} />
                      <Route path="/approved-title-underwriters/" element={<ApprovedUnderwriterPage />} />
                      <Route path="/tpo/" element={<WholesaleLending />} />
                      <Route path="/correspondent-lending/" element={<CorrespondentLending />} />
                      <Route path="/training-videos/" element={<TrainingVideos />} />
                      <Route path="/biweekly-vs-additional-payment/" element={<BiweeklyvsAdditionalCalculator />} />
                      <Route path="/disaster-recovery/" element={<DisasterRecovery />} />
                      <Route path="/faq/AcceptableIncomeDocumentation/" element={<IncomeDocumentation />} />
                      <Route path="/comparison/" element={<ComparisonPage />} />
                      <Route path="/hud-handbook-faq/" element={<HudHandbookFaq />} />
                      <Route path="/feedback/" element={<FeedbackPage />} />
                      <Route path="/hud-handbook-203k/" element={<HUD203kPage />} />
                      <Route path="/hud-handbook-summary/" element={<HUDSummaryPage />} />
                      <Route path="/swmc-market-rate-trends/" element={<MarketRateTrends />} />
                      <Route path="/Covid19-relief/" element={<Covid19Relief />} />
                      <Route path="/loan-officer-search/" element={<LoanOfficerSearch />} />
                      <Route path="/swmc-press-releases/" element={<SwmcPressRelease />} />
                      <Route path="/avoid-affinity-fraud/" element={<AffinityFraud />} />
                      <Route path="*" element={<ErrorFallback />} />
                      <Route path="/swmc-offer" element={<SwmcOffers />} />
                      <Route path="/underwriting-scenario" element={<UnderwritingScenario />} />
                      <Route path="/loss-mitigation" element={<ServicingPortal />} />
                      <Route path="/markets" element={<Markets />} />
                      <Route path="/loan-calculators-mortgage-calculators/appraisal-calculators/" element={<AppraisalCalculator />} />
                      <Route path="/loan-calculators-mortgage-calculators" element={<AngelAiCalculators />} />
                      <Route path="/loan-calculators-mortgage-calculators/rent-vs-buy-calculators" element={<AngelAiCalculators />} />
                      <Route path="/loan-calculators-mortgage-calculators/affordability-calculators" element={<AngelAiCalculators />} />
                      <Route path="/loan-calculators-mortgage-calculators/refinance-calculators" element={<AngelAiCalculators />} />
                      <Route path="/loan-calculators-mortgage-calculators/fixed-rate-calculators" element={<AngelAiCalculators />} />
                    </Routes>
                    <Suspense fallback={<div></div>}>
                      <ScrollToTop />
                    </Suspense>
                  </Suspense>
                }
              />
            </Routes>
          </Layout>
          
          <CookieSetting className="cookie-setting"
            privacyPolicyLink={process.env.REACT_APP_PRIVACY_POLICY_LINK}
            configUrl={process.env.REACT_APP_COOKIE_SETTING_JSON}
            analytics={process.env.REACT_APP_ANALYTICS_PATH}
          />
        </>
    
    </BrowserRouter>
  );
};

export default App;