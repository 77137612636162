import React, { lazy, Suspense, useState, useEffect } from "react";
import "./index.css";
import axios from "axios";
import Banner from "./Banner"
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const Portal = lazy(() => import("./Portal"));
const Services = lazy(() => import("./Services"));
const PhoneResponseTimes = lazy(() => import("./PhoneResponseTimes/PhoneRs"));
const WinMoreDeals = lazy(() => import("./WinMoreDeals"));
const Connections = lazy(() => import("./Connections"));
const TacaSection = lazy(() => import("./TacaSection"));
const LegacySunWestStory = lazy(() => import("./LegacySunWestStory"));
const AsSeenOnTV = lazy(() => import("./AsSeenOnTV"));
const NewPressReleaseComponent = lazy(() => import("./NewPressReleaseComponent"));
const ReachUs = lazy(() => import("./ReachUs"));
const ProductCarousel = lazy(() => import("./ProductCarousel"));
const HWBadge = lazy(() => import("./HWBadge"));
const DisclaimerSection = lazy(() => import("./DisclaimerSection"));
const VendorOrderModal = lazy(() => import("./Portal/VendorOrderModal"));
const ScrollToTop = lazy(() => import("../../utils/ScrollToTop"));
const Homepage = () => {
  const [showOtherComponents, setShowOtherComponents] = useState(false);
  const [vendorModalShow, setVendorModalShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleShowVendorModal = () => {
    setVendorModalShow(true);
  };
  const [gameOnQuotes, setGameOnQuotes] = useState([]);
  const handleShowModal = () => setShowModal(true);

  const extractVideoId = (url) => {
    try {
      const urlObj = new URL(url);
      if (urlObj.hostname.includes('youtube.com')) {
        if (urlObj.pathname.includes('/shorts/')) {
          return urlObj.pathname.split('/shorts/')[1];
        } else if (urlObj.pathname === '/watch') {
          return urlObj.searchParams.get('v');
        }
      } else if (urlObj.hostname.includes('youtu.be')) {
        return urlObj.pathname.slice(1);
      }
    } catch {
      console.error('Invalid YouTube URL:', url);
    }
    return null;
  };

  // Fetch quotes data
  useEffect(() => {
    const fetchGameOnQuotes = async () => {
      try {
        const { data } = await axios.get(process.env.REACT_APP_GAMEON_QUOTES);
        const quotesWithVideoId = data.map((item) => ({
          ...item,
          videoURL: item.video === 'true' ? extractVideoId(item.webPageURL) : null,
        }));
        setGameOnQuotes(quotesWithVideoId);
      } catch (err) {
        console.error(err);
      }
    };

    fetchGameOnQuotes();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.body.offsetHeight;
      const scrollPosition =
        window.scrollY ||
        window.pageYOffset ||
        document.documentElement.scrollTop;
      const maxScroll = documentHeight - windowHeight;
      const percentage = (scrollPosition / maxScroll) * 100;

      if (percentage > 1) {
        setShowOtherComponents(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  const location = useLocation();
  const isMainPage = location.pathname === "/";
  
  return (
    <>
      <Helmet>
        <link rel="preload" as="image" href="https://resources.swmc.com/images/gameon-quotes/colored-border.webp" fetchpriority="high"></link>
      </Helmet>
      <div>

        <Banner gameOnQuotes={gameOnQuotes} />

        <Suspense fallback={<div></div>}>
          <Portal click={handleShowModal}
            handleVendorClick={handleShowVendorModal}
          />
          {windowWidth >= 992 && (
            <Suspense fallback={<div>.</div>}>
              <Services />
            </Suspense>
          )}
        </Suspense>

        {showOtherComponents && (
          <>
            {windowWidth <= 991 && (
              <Suspense fallback={<div>.</div>}>
                <Services />
              </Suspense>
            )}
            <Suspense fallback={<div></div>}>
              <PhoneResponseTimes />
              <Suspense fallback={<div></div>}>
                <WinMoreDeals />
                <Suspense fallback={<div></div>}>
                  <Connections />
                  <Suspense fallback={<div></div>}>
                    <TacaSection />
                    <Suspense fallback={<div></div>}>
                      <LegacySunWestStory />
                      <Suspense fallback={<div></div>}>
                        <AsSeenOnTV />
                        <Suspense fallback={<div></div>}>
                          <div className="news">
                            <NewPressReleaseComponent />
                          </div>
                        </Suspense>
                        <Suspense fallback={<div></div>}>
                          <ReachUs />
                          <Suspense fallback={<div></div>}>
                            <ProductCarousel />
                            <Suspense fallback={<div></div>}>
                              <HWBadge />
                              <Suspense fallback={<div></div>}>
                                <DisclaimerSection />
                              </Suspense>
                            </Suspense>
                          </Suspense>
                        </Suspense>
                      </Suspense>
                    </Suspense>
                  </Suspense>
                </Suspense>
              </Suspense>
            </Suspense>
            <Suspense fallback={<div></div>}>
            <ScrollToTop />
          </Suspense>
          </>
        )}

      </div>
    </>
  );
}

export default Homepage;
